<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      Form
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateProductStatus'
}
</script>
